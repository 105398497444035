import React, {useEffect} from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
// import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import Stack from 'react-bootstrap/Stack';
import moment from 'moment';
import SocialShare from '@components/socialShare';
import { Routes } from '@components/routes';
import { VideoBannerFragmentGql, LatestVideosFragmentGql } from "@components/gql";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../pages/404"
import Loader from '@components/loader';
const AfterTitle = ({ date }) => {
    return (
        <Stack direction="horizontal" className="gap-24 gap-md-48">
            {date &&
                <div className="award-card__date">
                    {moment(date).format("D MMM YYYY")}
                </div>
            }
            <SocialShare
                shareLabel="Share"
                hideShareIcon={true}
            />
        </Stack>
    )
}

const VideoDetails = (props) => {
    const GET_DETAIL_VIDEOS = gql`
    query GetVideosDetails($Slug: String!) {
        videos(where:{Slug: $Slug}){
            Pagename
        Title
        Slug
        Date
        id
        HeaderTemplate
        imagetransforms
        BannerImage {
            ... on ComponentModulesBannerImage {
               ...VideoBannerFragmentGql
            }
            ... on ComponentModulesLatestVideos {
                ...LatestVideosFragmentGql
            }
        }
        Metadata {
            title
            description
            image {
                url
                
            }
        }
  
        }
    }
    ${VideoBannerFragmentGql}
    ${LatestVideosFragmentGql}
`;
    const { loading, error, data } = useQuery(GET_DETAIL_VIDEOS, {
        variables: { Slug: props.myslug }
    });

    // const {  video } = data
    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.scrollTo(0,0)
            // window.addEventistener('contextmenu', function (e) {
            //     e.preventDefault();
            //   });
        }
    }, [data])
    if (loading) return (
        <Layout loading={loading}>
            <Loader loading={loading} error={error} hideText={true} fullScreen />
        </Layout>
    );
    if (data?.videos?.length === 0) return (
        <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
    )
    return (

        <Layout popularSearch={"Media_Layout"}>
            {data?.videos?.map((Page, i) => {
                const breadcrumbs = [
                    { label: "Videos", url: `${Routes.MediaPage}${Routes.VideosPage}` },
                    { label: Page?.Pagename, url: `/${Page?.Slug}` }
                ]
                return (
                    <>

                        <Seo
                            title={Page?.Pagename}
                            description={"Watch "+Page?.Pagename+" here and subscribe to our YouTube channel to stay up-to-date about visuals of our properties for rent and for sale, the popular communities, interviews and our events."}
                            image={Page?.Metadata?.image?.url ? Page.Metadata.image.url : null}
                        />
                        <BannerRender
                            page={Page}
                            video={Page}
                            breadcrumbs={breadcrumbs}
                            className="video-bottom-padding"
                            afterTitle={<AfterTitle date={Page?.Date} />}
                        />
                        {Page?.BannerImage &&
                            <ModuleRender
                                moduleData={Page?.BannerImage}
                            />
                        }
                    </>
                )
            })}

        </Layout>
    );
}

export default VideoDetails

// export const query = graphql`
// query VideoDetailsQuery($id: ID!) {
//     glstrapi {
//         video(id: $id, publicationState: LIVE) {
//             Pagename
//             Title
//             Slug
//             Date
//             id
//             HeaderTemplate
//             imagetransforms
//             BannerImage {
//                 ... on GLSTRAPI_ComponentModulesBannerImage {
//                     ...VideoBannerFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesLatestVideos {
//                     ...LatestVideosFragment
//                 }
//             }
//             Metadata {
//                 title
//                 description
//                 image {
//                     url
//                     url_sharp {
//                         childImageSharp {
//                             resize(width: 1200) {
//                                 src
//                                 height
//                                 width
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `